import React, { HTMLAttributes } from 'react';
import styles from './index.module.scss'

const tags = ['div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span'] as const;

type Tag = typeof tags[number];

interface Props extends HTMLAttributes<HTMLElement> { }

const createComponentCache = (styleName: string) => {
    const componentCache = new Map<Tag, React.FC<Props>>();

    const createProxyComponent = new Proxy({} as { [K in Tag]: React.FC<Props> }, {
        get: (_target, key: Tag) => {
            if (!componentCache.has(key)) {
                const TagComponent: React.FC<Props> = ({ children, className, ...rest }) => {
                    return React.createElement(key, {
                        ...rest,
                        className: `${styles.base} ${styles[styleName]} ${className}`
                    }, children);
                };
                componentCache.set(key, TagComponent);
            }
            return componentCache.get(key);
        },
    });

    return createProxyComponent;
};

const Typography = (styleName: string) => {
    const componentCache = createComponentCache(styleName);

    return componentCache;
};

// export const H1Bold = Typography('H1Bold').h1;
// export const H2Medium = Typography('H2Medium').h2;
// export const H2Regular = Typography('H2Regular').h2;
// export const Fs36 = Typography('fs36');

export const Fs32Fw700White = Typography('Fs32Fw700White');
export const Fs18Fw600White = Typography('Fs18Fw600White');
export const Fs16Fw500White = Typography('Fs16Fw500White');
export const Fs16Fw500Muted = Typography('Fs16Fw500Muted');
export const Fs14Fw500Muted = Typography('Fs14Fw500Muted');

