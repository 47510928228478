import React, { RefObject, useEffect, useRef, useState } from 'react';
import styles from './index.module.scss'
import { useOutsideClick } from 'utils/useOutsideClick';
import SearchIcon from 'assets/icons/search-icon.svg'

export interface Option {
    value: string;
    label: string;
    image?: string
    languageCode?: string
}

interface SelectWithSearch {
    options: Option[];
    // setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
    // isVisible: boolean
    setOption: (a: any) => void
    option: Option | null | undefined
    icon: string
    className?: any
    dependetOption?: any
    boxClassName?: any
}

export const withStyle = (styleName: string) => {
    return ({ options, setOption, option, icon, dependetOption, className, boxClassName }: SelectWithSearch) => {

        const [isOpen, setIsOpen] = useState(false);
        const [search, setSearch] = useState('');
        const inputRef = useRef<HTMLInputElement>(null);
        const selectRef = useRef<HTMLDivElement>(null);

        const filteredOptions = options.filter(opt =>
            opt.label.toLowerCase().includes(search.toLowerCase())
        );

        const handleClickOutside = (event: MouseEvent) => {
            if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        // console.log(dependetOption, options, 'dependet');

        useEffect(() => {
            if (dependetOption) {
                console.log(options, option, dependetOption);
                
                setOption(options[0] || option);
            }
        }, [dependetOption])
        useEffect(() => {
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, []);
        useEffect(() => {
            if (!option) return;

            setSearch(option.label)
        }, [option])
        const handleSelectOption = (option: Option) => {
            setTimeout(() => {
                setOption(option);
                setIsOpen(false);
            });
        };

        useEffect(() => {
            function handleKeyDown(event: KeyboardEvent) {
                if (event.key === 'Escape') {
                    setIsOpen(false);
                }
            }

            window.addEventListener('keydown', handleKeyDown);

            return () => {
                window.removeEventListener('keydown', handleKeyDown);
            };
        }, []);

        const handleOpen = () => {
            setIsOpen((prev) => !prev);
            if (!isOpen) {
                setSearch('');
            }

            setTimeout(() => {
                if (inputRef.current && !isOpen) {
                    inputRef.current.focus();
                }
            });
        };

        return (
            <>
                <div className={`${styles.selectSearch} ${styles[styleName]} ${className}`} ref={selectRef}>
                    <div
                        onClick={handleOpen}
                        className={`${styles.allBox} ${boxClassName}`} >
                        {option ?
                            <div className={`${styles.boxSelect} ${styles.dopActive}`}>
                                <div className={styles.labelSearch}>
                                    {option.image && <img src={option.image} alt='Icon' />}
                                    {option.label}
                                </div>
                                <img src={icon} />
                            </div> :
                            <div className={`${styles.boxSelect}`}>
                                <span>Выбрать...</span>
                                <img src={icon} />
                            </div>
                        }
                    </div>
                    {isOpen && (
                        <div className={styles.boxInputIcon}>
                            <div className={styles.boxFlexInputSearch}>
                                <img src={SearchIcon} />
                                <input
                                    type="text"
                                    value={search}
                                    onChange={e => setSearch(e.target.value)}
                                    className={styles.searchInput}
                                    placeholder='Выберите...'
                                    ref={inputRef}
                                />
                            </div>
                            <ul className={styles.ul}>
                                {filteredOptions.map(option => (
                                    <div key={option.value} className={styles.boxImgLi} data-google-lang={option.value}
                                        onClick={() => handleSelectOption(option)}>
                                        <li>
                                            {option.image && <img src={option.image} alt='Icon' />}
                                            {option.label}
                                        </li>
                                    </div>
                                ))}
                                {
                                    filteredOptions.length === 0 ? <span className={styles.notfound}>Ничего не найдено</span> : <></>
                                }
                            </ul>
                        </div>
                    )}
                </div>
            </>
        );
    }
};

export const SelectWithSearchGreen = withStyle("SelectWithSearchGreen")
export const SelectWithSearchNoBg = withStyle("SelectWithSearchNoBg")
export const SelectWithSearchGray = withStyle("SelectWithSearchGray")
export const SelectWithSearchPurple = withStyle("SelectWithSearchPurple")
export const SelectWithSearchNoBGInput = withStyle("SelectWithSearchNoBGInput")