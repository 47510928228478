import { Routes, Route, useLocation } from "react-router-dom";
import { Main } from './pages/Main';
import { useJwtStore } from 'stores/jwt';
import { RequireAuth } from 'utils/requireAuth';
import { RequireRole } from 'utils/requireRole';
import { AxiosJWT } from "services/axiosJWT";
import { ToastContainer } from "react-toastify";
import styles from './index.module.scss'

function App() {
  return (
    <>
      <AxiosJWT />
      <ToastContainer />
      <Routes>
        <Route path={"/"} element={<Main />}></Route>
        <Route element={<RequireAuth />} >
          {/*  <Route path='/cabinet/*' element={<Cabinet />}></Route> */}
        </Route>
        <Route element={<RequireRole />} >
          {/* <Route path='/admin-panel/*' element={<AdminPanel />}></Route> */}
        </Route>
      </Routes>
      
      <footer className={styles.prava}>
      © All rights reserved. Created RateRaiders with CircuitBlaze Team 2024
      </footer>
    </>
  );
}

export default App;
