export function formatUSDT(number: number) {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol' }).format(number);
}

export function formatNumber(number: number) {
    return new Intl.NumberFormat('de-DE').format(number)
}

export function formatAsPercentage(num: number) {
    return new Intl.NumberFormat('default', {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(num / 100);
}
