import { useCallback, useEffect, useState } from "react";

import { Wrapper } from "components/wrapper/wrapper";
import { ButtonNoBg, ButtonPurple } from "ui/buttons";
import { Fs14Fw500Muted, Fs16Fw500Muted, Fs18Fw600White, Fs32Fw700White } from "components/typography";
import { SelectWithSearchNoBGInput, SelectWithSearchPurple } from "components/cleverSearch2";

import arrow from 'assets/images/arrow.png';
import sber from 'assets/images/sber.png';
import teth from 'assets/images/USDT.png';
import update from 'assets/images/update.png';
import bin from 'assets/images/bin.png';

import styles from './index.module.scss'
import { InputDark, InputNobg } from "ui/inputs/input";
import { useQuery, useQueryClient } from "react-query";
import { getAllRates, getAllRatesBannksByFiat, getAllRatesFiats } from "api";
import { ExchangeData } from "interfaces";
import { formatNumber } from "utils/formatNumber";
import { useNavigate } from "react-router-dom";

export interface Option {
    value: string;
    label: string;
    image?: string
}

export const options = [
    {
        value: 'first',
        label: 'USDT',
        image: teth,
    },
];

export const options2 = [
    {
        value: 'averageCursFirstFive',
        label: 'Цена (1-5)',
    },
    {
        value: 'averageCursFirstFiveWithoutTwoFirst',
        label: 'Цена (3-7)',
    },
];

export const Main = () => {

    const [main, setMain] = useState<string>('main');
    const [buy, setBuy] = useState<string>('buy');
    const [isIncrease, setIncrease] = useState<boolean>(true);

    const [option, setOption] = useState(options[0]);

    const [option2, setOption2] = useState(options2[0]);

    const [numberInput, setNumberInput] = useState<number>(0)

    const { data: dataFiats, isLoading: isLoadingFiats } = useQuery(
        ['all-fiats'],
        () => getAllRatesFiats(),
        { keepPreviousData: true }
    );

    const options4 = dataFiats?.map(el => ({
        value: el,
        label: el,
    })) || [];

    const [option4, setOption4] = useState(options4?.[0] || { value: 'RUB', label: 'RUB' });

    const { data: dataBank, isFetchedAfterMount: isLoadingBank } = useQuery(
        ['all-bank', option4?.value],
        () => getAllRatesBannksByFiat(String(option4?.value)),
        { keepPreviousData: true }
    );

    const options3 = dataBank?.map(el => ({
        value: el,
        label: el,
    })) || [];

    const [option3, setOption3] = useState(options3?.[0] || { value: 'Тинькофф', label: 'Тинькофф' });

    const params = {
        amount: numberInput || 10000,
        fiat: String(option4?.value),
        tradeType: buy === "buy" ? "BUY" : "SELL",
        bankName: option3.value,
        sortBy: option2.value,
        isSortASC: isIncrease,
    }

    const { data, isFetching } = useQuery(
        ['all-rates', params],
        () => getAllRates(params),
        {
            keepPreviousData: true,
            refetchInterval: 10000
        }
    );

    const [activeIndex, setActiveIndex] = useState(null);

    const toggleDetails = useCallback((index: any) => {
        setActiveIndex(activeIndex === index ? null : index);
    }, [activeIndex]);

    const queryClient = useQueryClient();

    const updateFunc = useCallback(() => {
        queryClient.invalidateQueries(['all-rates', params])
    }, [queryClient, params]);

    console.log(isIncrease, 'isIncrease');

    return (
        <Wrapper className={styles.wrapper}>
            {/* <div className={styles.selectBlock}>
                {main === 'main' ?
                    <>
                        <ButtonPurple onClick={() => setMain('main')}>Главная</ButtonPurple>
                    </>
                    :
                    <>
                        <ButtonNoBg onClick={() => setMain('main')}>Главная</ButtonNoBg>
                    </>
                }
            </div> */}
            <div className={styles.texts}>
                <Fs32Fw700White.h1>Выберите фильтры ниже и получите информацию по объявлениям на биржах</Fs32Fw700White.h1>
                <Fs16Fw500Muted.p>По клику на элемент в списке, вы можете получить более подробную информацию по объявлениям</Fs16Fw500Muted.p>
            </div>
            <div className={styles.filters}>
                <div className={styles.leftSide}>
                    <div className={styles.selectBlock}>
                        {buy === 'buy' ?
                            <>
                                <ButtonPurple onClick={() => {
                                    setBuy('buy')
                                    setIncrease(true)
                                }}>Купить</ButtonPurple>
                                <ButtonNoBg onClick={() => {
                                    setBuy('sell')
                                    setIncrease(false)
                                }}>Продать</ButtonNoBg>
                            </>
                            :
                            <>
                                <ButtonNoBg onClick={() => {
                                    setBuy('buy')
                                    setIncrease(true)
                                }}>Купить</ButtonNoBg>
                                <ButtonPurple onClick={() => {
                                    setBuy('sell')
                                    setIncrease(false)
                                }}>Продать</ButtonPurple>
                            </>
                        }
                    </div>
                    <SelectWithSearchPurple
                        icon={arrow}
                        setOption={setOption}
                        option={option}
                        options={options} />
                    {/* <InputPurple
                        onChange={(e: any) => setNumberInput(e.target.value)}
                        type={"text"}
                        placeholder="Введите сумму"
                        className={styles.inp} /> */}

                    <div className={styles.box}>
                        <InputNobg placeholder="Введите сумму" type={"number"} onChange={(e: any) => setNumberInput(e.target.value)} />
                        <SelectWithSearchNoBGInput
                            icon={arrow}
                            setOption={setOption4}
                            option={option4}
                            options={options4}
                        />
                    </div>

                    <SelectWithSearchPurple
                        icon={arrow}
                        setOption={setOption3}
                        option={option3}
                        options={options3}
                        dependetOption={isLoadingBank}
                    />

                    <SelectWithSearchPurple
                        icon={arrow}
                        setOption={setOption2}
                        option={option2}
                        options={options2}
                        className={styles.widthselect} />
                </div>
                <ButtonPurple className={styles.purpleBtn} onClick={() => updateFunc()}>
                    <img src={update} className={isFetching ? styles.rotate : ''} alt="update" />
                    Обновить
                </ButtonPurple>
            </div>
            <div className={styles.postsBlock}>
                <div className={styles.head}>
                    <Fs14Fw500Muted.span className={styles.h1}>Биржа</Fs14Fw500Muted.span>
                    <Fs14Fw500Muted.span className={styles.h2} onClick={() => setIncrease(!isIncrease)}>Ср. цена (1-5) / (3-7)</Fs14Fw500Muted.span>
                    <Fs14Fw500Muted.span className={styles.h3}>Лимиты </Fs14Fw500Muted.span>
                    <Fs14Fw500Muted.span className={styles.h4}>Способ оплаты</Fs14Fw500Muted.span>
                </div>
                <div className={styles.posts}>
                    {data?.map((el, idx) => (
                        <div className={styles.column}>
                            <div className={`${styles.post} ${activeIndex === idx ? styles.active : null}`} key={idx}>
                                <div className={styles.opl}>
                                    <img src={el.exchangeImage} alt={el.exchangeName} />
                                    <Fs18Fw600White.span>{el.exchangeName}</Fs18Fw600White.span>
                                </div>
                                <Fs18Fw600White.span className={styles.cost}>{formatNumber(el.averageCursFirstFive)} / {formatNumber(el.averageCursFirstFiveWithoutTwoFirst)}</Fs18Fw600White.span>
                                <Fs18Fw600White.span className={styles.limits}>
                                    {
                                        el?.data[0]?.minAmount ?
                                            <div className={styles.limits}>
                                                <Fs18Fw600White.span >Лимит: {formatNumber(el?.data?.[0]?.minAmount)} {option4?.value} - {formatNumber(el?.data?.[0]?.maxAmount)} {option4?.value}</Fs18Fw600White.span>
                                                <Fs18Fw600White.span >Доступно: {formatNumber(el?.data?.[0]?.liquidity)} USDT</Fs18Fw600White.span>
                                            </div>
                                            :
                                            <>Нет курса</>
                                    }
                                </Fs18Fw600White.span>
                                <div className={styles.opl}>
                                    <Fs18Fw600White.span>{option3.label}</Fs18Fw600White.span>
                                </div>
                                {el.data.length !== 0 ? <ButtonPurple className={`${styles.more} ${activeIndex === idx ? styles.activeBtn : null}`} onClick={() => toggleDetails(idx)}>Подробнее</ButtonPurple>
                                    :
                                    <Fs18Fw600White.span>Нет данных</Fs18Fw600White.span>
                                }
                            </div>
                            {activeIndex === idx && (
                                <div className={`${styles.posts} ${activeIndex === idx ? styles.active : null}`}>
                                    {el.data.map((detail, detailIdx) => (
                                        <div className={styles.post} key={detailIdx}>
                                            <div className={styles.opl}>
                                                <Fs18Fw600White.span>{detail.name}</Fs18Fw600White.span>
                                            </div>
                                            <Fs18Fw600White.span className={styles.cost}>{detail.price}</Fs18Fw600White.span>
                                            <div className={styles.limits}>
                                                <Fs18Fw600White.span >Лимит: {formatNumber(detail.minAmount)} {option4?.value} - {formatNumber(detail.maxAmount)} {option4?.value}</Fs18Fw600White.span>
                                                <Fs18Fw600White.span >Доступно: {formatNumber(detail.liquidity)} USDT</Fs18Fw600White.span>
                                            </div>
                                            <div className={styles.opl}>
                                                <Fs18Fw600White.span>{option3.label}</Fs18Fw600White.span>
                                            </div>
                                            <a href={el.exchangeURL} target="_blank">
                                                <ButtonPurple className={`${styles.more} ${activeIndex === idx ? styles.activeBtn : null}`}>
                                                    Перейти
                                                </ButtonPurple>
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </Wrapper>
    );
}

