import { api } from 'services/api';
import { ExchangeData, IRegisterForm, ISignInForm, ISignRes } from 'interfaces';

export const signUp = async (data: IRegisterForm): Promise<Boolean> => {
    let response = await api.post('auth/signup', data);
    return response.data;
}
export const signIn = async (data: ISignInForm): Promise<ISignRes> => {
    let response = await api.post('auth/login', data);
    return response.data;
}

export const getAllRates = async (params: any): Promise<ExchangeData[]> => {
    let response = await api.get(`/all-rates`, { params });
    return response.data;
}

export const getAllRatesFiats = async (): Promise<string[]> => {
    let response = await api.get(`/all-rates/getFiats`);
    return response.data;
}

export const getAllRatesBannksByFiat = async (fiat: string): Promise<string[]> => {
    let response = await api.get(`/all-rates/banksByFiat?fiat=${fiat}`);
    return response.data;
}